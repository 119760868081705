.user__wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  @media (max-width: 1160px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 770px) {
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 390px) {
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
  }
  .user__card {
    max-width: 300px;
    width: 100%;
    border-radius: 8px;
    background-color: rgb(17, 17, 82);
    color: #fff;
    border: 1px solid rgb(17, 17, 82);
    &__frame {
      width: 100%;
      border-radius: 8px 8px 0 0;
      height: 130px;
      background-color: #fff;
      color: black;
      @media (max-width: 390px) {
        height: 160px !important;
      }
      @media (max-width: 350px) {
        height: 120px !important;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 8px 8px 0 0;
        object-fit: cover;
      }
      @media (max-width: 450px) {
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &__content {
      padding: 12px;
      h1 {
        font-size: 18px;
        letter-spacing: 1px;
        margin-bottom: 5px;
        color: green;
        @media (max-width: 490px) {
          font-size: 16px;
        }
        @media (max-width: 450px) {
          font-size: 13px;
        }
      }
      h2 {
        font-size: 16px;
        letter-spacing: 1px;
        margin-bottom: 5px;
        @media (max-width: 490px) {
          font-size: 14px;
        }
        @media (max-width: 450px) {
          font-size: 11px;
        }
      }
      p {
        margin-bottom: 5px;
      }
      h3 {
        font-size: 14px;
        letter-spacing: 1px;
        margin-bottom: 5px;
        @media (max-width: 490px) {
          font-size: 12px;
        }
        @media (max-width: 450px) {
          font-size: 10px;
        }
      }
    }
  }
  .overlay__image__module {
    width: 100%;
    height: 100vh;
    inset: 0;
    background-color: #0006;
    position: fixed;
    display: none;
  }
  .image__module {
    width: 70%;
    height: 70vh;
    position: fixed;
    left: 50%;
    top: 50%;
    display: none;
    transform: translate(-50%, -50%);
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      backdrop-filter: blur(20px);
      // background-color: #ddd;
    }
  }
  .overlay__image__module.show__big__img,
  .image__module.show__big__img {
    display: inline-block;
  }
}
