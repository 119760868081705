.main {
  width: 100%;
  height: 100vh;
  background: url(../../assets/images/main-background.webp) center no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    background-size: cover;
  }
  .customer__id {
    background-color: green;
    width: 400px;
    padding: 10px;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    color: #fff;
    opacity: 0;
    z-index: -3;
    @media (max-width: 420px) {
      width: 340px;
    }
    @media (max-width: 350px) {
      width: 300px;
    }
    h1 {
      font-size: 24px;
      letter-spacing: 1px;
      margin-bottom: 10px;
      @media (max-width: 400px) {
        font-size: 20px;
      }
    }
    h2 {
      font-size: 14px;
      letter-spacing: 4px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    p {
      font-size: 24px;
      color: #fff;
      margin-bottom: 10px;
      @media (max-width: 400px) {
        font-size: 20px;
      }
    }
    h4 {
      font-size: 16px;
      font-weight: 400;
      @media (max-width: 400px) {
        font-size: 14px;
        letter-spacing: 1.5px;
      }
    }
  }
  .customer__id.show__id {
    opacity: 1;
    z-index: 100;
  }
  &__box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    max-width: 300px;
    width: 100%;
    padding: 0 10px;
    .main__id__btn,
    .main__complaint__btn {
      width: 100%;
      height: 50px;
      border: none;
      border-radius: 4px;
      background-color: rgb(17, 17, 82);
      color: #fff;
      font-size: 20px;
      cursor: pointer;
      position: relative;
      z-index: 50;
    }
    &__bottom {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      width: 100%;
      height: 50px;
      .main__price__btn,
      .main__branch__btn {
        width: 100%;
        border-radius: 4px;
        border: none;
        outline: none;
        background-color: rgb(17, 17, 81);
        cursor: pointer;
        color: #fff;
        font-size: 18px;
        position: relative;
      }
    }
    &__socialmedia {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      background-color: green;
      color: #fff;
      border-radius: 4px;
      padding: 10px;
      h3 {
        font-weight: 400;
      }
      a {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        text-decoration: none;
        color: #fff;
        svg {
          font-size: 18px;
        }
      }
    }
    &__location {
      h3 {
        font-weight: 400;
        font-size: 18px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        gap: 8px;
      }
      p {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 10px;
      }
      h4 {
        font-size: 14px;
        font-weight: 400;
      }
    }
    button:hover {
      background-color: rgb(17, 17, 65);
    }
  }
}

.overlay {
  position: fixed;
  width: 100%;
  inset: 0;
  height: 100vh;
  background-color: #0009;
  opacity: 0;
  z-index: -10;
  transition: 0.5s;
}
.overlay.show__overlay {
  opacity: 1;
  z-index: 10;
}

.take__id__box,
.complaint__box,
.price__box,
.branch__box {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 450px;
  padding: 30px;
  background-color: rgb(17, 17, 82);
  border-radius: 16px;
  opacity: 0;
  z-index: -10;
  transition: 0.5s;
  padding-top: 40px;
  @media (max-width: 600px) {
    width: 370px;
  }
  .confirm__img__btn {
    width: 140px;
    height: 30px;
    margin-top: 10px;
    background-color: green;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
  }

  form {
    .form__top {
      display: flex;
      gap: 10px;
      margin-bottom: 20px;
      &__inp__box {
        max-width: 500px;
        width: 100%;
        label {
          margin-bottom: 10px;
          display: inline-block;
          color: #fff;
          cursor: pointer;
        }
        input {
          height: 40px;
          width: 100%;
          border: none;
          outline: none;
          padding: 10px;
          border-radius: 10px;
          font-size: 18px;
        }
      }
    }
    .main__inp__box {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      div {
        display: flex;
        align-items: center;
        span {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          font-size: 20px;
          width: 60px;
          height: 40px;
          background-color: #fff;
          border-radius: 10px 0 0 10px;
        }
      }
      label {
        margin-bottom: 10px;
        color: #fff;
        cursor: pointer;
      }
      input {
        height: 40px;
        width: 100%;
        border: none;
        outline: none;
        padding: 10px;
        padding-left: 5px;
        border-radius: 0 10px 10px 0;
        font-size: 18px;
      }
    }
    select {
      width: 100%;
      height: 40px;
      outline: none;
      border: none;
      border-radius: 4px;
      padding: 10px;
      font-size: 16px;
      option {
        outline: none;
        border: none;
      }
    }
  }
  .send__btn,
  .id__olish {
    width: 100px;
    border-radius: 10px;
    height: 40px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    font-size: 18px;
    justify-content: center;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: green;
    color: #fff;
  }
}

.price__box,
.branch__box {
  color: #fff;
  overflow-y: auto;
  &__title {
    margin-bottom: 16px;
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 12px;
    li {
      display: flex;
      gap: 6px;
      align-items: center;
      font-size: 18px;
      svg {
        font-size: 24px;
      }
      a {
        color: #fff;
        // text-decoration: none;
      }
    }
  }
}

.price__box {
  height: 500px;
  top: 40%;
  li:nth-child(2) {
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
  }
  p {
    font-size: 15px;
  }
  h4 {
    font-size: 16px;
  }
}

.take__id__box {
  height: 550px;
}

.complaint__box {
  height: 500px;
  input {
    border-radius: 10px !important;
    padding-left: 10px !important;
  }
  .complaint__phone__inp {
    border-radius: 0px 10px 10px 0 !important;
  }
  textarea {
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 10px;
    resize: none;
    font-size: 18px;
  }
}

.take__id__box.show__id__module,
.complaint__box.show__complaint__box,
.price__box.show__price__module,
.branch__box.show__branch__module {
  opacity: 1;
  z-index: 100;
  top: 50%;
}

.main__box.hide__buttons {
  opacity: 0;
}

.close__id__module {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
  background-color: transparent;
  color: #fff;
  border: none;
  &:hover {
    color: red;
  }
}
