.complaint__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
  }
  @media (max-width: 470px) {
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
  }
  .complaint__card {
    max-width: 300px;
    width: 100%;
    background-color: rgb(17, 17, 82);
    color: #fff;
    padding: 10px;
    position: relative;
    border-radius: 16px;
    .complaints__box.show__complaint {
      opacity: 1;
      z-index: 4;
    }
    .complaint__fullname {
      font-size: 20px;
      margin-bottom: 8px;
    }
    .complaint__phone {
      font-size: 18px;
      margin-bottom: 8px;
    }
    .complaint__id {
      margin-bottom: 6px;
      padding-bottom: 10px;
      letter-spacing: 2px;
    }
    .complaint__text {
      letter-spacing: 1px;
      border-radius: 4px;
      padding: 4px;
      border: 1px solid #eee;
      height: 150px;
      overflow-y: auto;
    }
    h4 {
      position: absolute;
      right: 10px;
      font-size: 25px;
      color: #ddd;
    }
  }
}
