.loading {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(17, 17, 82);
  z-index: 100;
  @media (max-width: 550px) {
    display: none;
  }
  &__box {
    display: flex;
    align-items: center;
    gap: 10px;
    animation: loadingScale 1.5s 1;
    transition: 0.5s;
    .animation__icon__box {
      position: relative;
      z-index: 5;
      text-align: center;
      width: 350px;
      background-color: rgb(17, 17, 82);
    }
    h1 {
      color: #fff;
      font-size: 100px;
      font-weight: 600;
      text-transform: uppercase;
      transition: 0.5s;
      margin-left: -350px;
      animation: loading 1.5s 1;
      animation-delay: 1s;
    }
    .title__show {
      margin: 0;
    }
  }
  svg {
    font-size: 250px;
    color: #fff;
  }
}

.loading.remove__animation {
  display: none;
}

@keyframes loading {
  0% {
    margin-left: -350px;
  }
  100% {
    margin-left: 0;
  }
}

@keyframes loadingScale {
  0% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(auto);
  }
}

// LOADING RESPONSIVE

.loading__mini {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(17, 17, 82);
  z-index: 100;
  display: none;
  @media (max-width: 550px) {
    display: flex;
  }
  .loading__box__mini {
    display: flex;
    align-items: center;
    gap: 10px;
    animation: loadingScale 1.5s 1;
    transition: 0.5s;
    .animation__icon__box__mini {
      position: relative;
      z-index: 5;
      text-align: center;
      width: 150px;
      background-color: rgb(17, 17, 82);
    }
    h1 {
      color: #fff;
      font-size: 40px;
      font-weight: 600;
      text-transform: uppercase;
      transition: 0.5s;
      margin-left: -150px;
      animation: loadingResponsive 1.5s 1;
      animation-delay: 1s;
    }
    .title__show__mini {
      margin: 0;
    }
  }
  svg {
    font-size: 150px;
    color: #fff;
  }
}

.loading__mini.remove__animation__mini {
  display: none;
}

@keyframes loadingResponsive {
  0% {
    margin-left: -150px;
  }
  100% {
    margin-left: 0;
  }
}

@keyframes loadingScale {
  0% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(auto);
  }
}
