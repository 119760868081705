@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

li {
  list-style: none;
}

.gift-1,
.gift-2,
.gift-3 {
  position: fixed;
  color: rgb(17, 17, 65);
  font-size: 70px;
  transform: rotate(30deg);
  animation: gift-1Anim 6s infinite alternate;
}

.gift-1 {
  top: 20%;
  left: 20%;
  font-size: 80px;
}

.gift-2 {
  top: 40%;
  right: 15%;
  font-size: 100px;
  animation: gift-2Anim 4s infinite alternate;
}

.gift-3 {
  bottom: 15%;
  right: 50%;
  animation: gift-3Anim 6s infinite alternate;
}

@keyframes gift-1Anim {
  0% {
    transform: rotate(-50deg);
  }
  100% {
    transform: rotate(30deg);
  }
}

@keyframes gift-2Anim {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes gift-3Anim {
  0% {
    transform: rotate(50deg);
  }
  100% {
    transform: rotate(-30deg);
  }
}
