.login {
  padding: 10px;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(17, 17, 82);
}
.login__box {
  width: 400px;
  height: 300px;
  background-color: #fff;
  padding: 30px;
  border-radius: 16px;
  form {
    display: flex;
    flex-direction: column;
    h1 {
      margin-bottom: 20px;
      color: rgb(17, 17, 82);
    }
    input,
    .login__btn {
      margin-bottom: 20px;
      height: 40px;
      border-radius: 10px;
      padding: 10px;
      outline: none;
      border: 2px solid rgb(17, 17, 82);
      font-size: 18px;
    }
    .login__btn {
      width: 100px;
      padding: 0;
      background-color: transparent;
      cursor: pointer;
      background-color: rgb(17, 17, 82);
      color: #fff;
    }
    .password__inp__box {
      width: 100%;
      height: 43px;
      display: flex;
      align-items: center;
      border: 2px solid rgb(17, 17, 82);
      border-radius: 10px;
      margin-bottom: 20px;
      input {
        width: 100%;
        border: none;
        margin-bottom: 0;
      }
      button {
        width: 40px;
        height: 40px;
        border-radius: 10px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
