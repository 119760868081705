.admin {
  display: grid;
  grid-template-columns: 300px 1fr;
  @media (max-width: 700px) {
    grid-template-columns: 200px 1fr;
  }
  @media (max-width: 500px) {
    display: block;
  }
  &__navbar {
    display: flex;
    position: fixed;
    z-index: 5;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    color: rgb(17, 17, 82);
    background-color: #fff;
    display: none;
    @media (max-width: 500px) {
      display: flex;
    }
    button {
      font-size: 20px;
      background-color: transparent;
      color: rgb(17, 17, 82);
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      svg {
        font-size: 24px;
      }
    }
    &__katalog {
      position: fixed;
      top: -250px;
      width: 100%;
      height: 140px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      background-color: rgb(17, 17, 82);
      transition: 0.5s;
      display: none;
      @media (max-width: 500px) {
        display: flex;
        z-index: 4;
        top: -120px;
      }
      a {
        text-decoration: none;
        color: #fff;
        padding: 10px;
      }
      a.active {
        background-color: #fff;
        color: black;
      }
    }
    &__katalog.show__katalog {
      top: 58px;
    }
  }
  &__sidebar {
    height: 100vh;
    background-color: rgb(17, 17, 82);
    padding: 30px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 500px) {
      display: none;
    }
    h2 {
      margin-bottom: 20px;
    }
    .admin__collection {
      list-style: none;
    }
    .admin__links {
      display: block;
      padding: 10px;
      text-decoration: none;
      color: #fff;
      border-radius: 4px;
      @media (max-width: 700px) {
        font-size: 14px;
        padding: 6px;
      }
    }
    .admin__links.active {
      background-color: #fff;
      color: black;
    }
    button {
      width: 140px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      border-radius: 4px;
      cursor: pointer;
      gap: 10px;
    }
  }
  &__content {
    height: 100vh;
    padding: 30px;
    overflow-y: auto;
    @media (max-width: 500px) {
      width: 100%;
      height: auto;
      padding-top: 100px;
    }
  }
}
